import React from "react"
import classnames from 'classnames/bind'

// Components
import Layout from '@/components/common/Layout'

// Lib MISC
import '@/styles/main.scss'
import styles from '@/styles/static.scss'

const cx = classnames.bind(styles)

export default function PrivacyPolicy ({ lang = 'en' }) {
  return <Layout lang={lang}>
    <div className={cx('static-banner')}>
      <p className={cx('static-banner-title')}>Privacy Policy</p>
    </div>
    <div className={cx('static-content')}>
      <p>What information do we collect?</p>
      <p>We at ReCards care about our Website visitors and other stakeholders, and that means we care about you and your privacy. We always collect personal information responsibly and with your privacy in mind.</p>
      <br />
      <p>It is important to us that you are aware of what information we collect about you and how it is collected.</p>
      <br />
      <p>In order to provide our services, such as when you submit a form on our website, we need to collect certain personal information. You may directly or indirectly give us information about yourself in a variety of ways. Typical examples are:</p>
      <br />
      <li>You are interested in our service and ask for free trial for our solution</li>
      <li>You are interested in our service and ask for a demo, guides</li>
      <li>You are interested to work for us and visit our website</li>
      <li>You created digital name card with our online tool</li>
      <br />
      <p>What do we do with your information?</p>
      <br />
      <p>We at ReCards care about our website visitors and other stakeholders, and that means we care about you and your privacy. We always collect personal information responsibly and with your privacy in mind.</p>
      <br />
      <p>It is important to us that you are aware of how we handle your personal information. There are different scenarios where we need to process your information depending on purpose.</p>
      <p>Typical examples are:</p>
      <li>We need your information to create digital name card for you</li>
      <li>We might need you to confirm your contact information before giving you access to a free trial or sending you our white paper(s)</li>
      <li>We might need your contact information if you want to apply for a position at Recards. For example, when you are visiting our website and want to have access to a free trial to test our service, we need your email and contact information to be able to validate your request before sending you login details.</li>
      <br />
      <p>Privacy Policy</p>
      <p>ReCards is dedicated to protecting your privacy. This Privacy Policy explains how ReCards collects and uses your personal data. It also describes your rights towards us and how to exercise them. You may always reach out to us on matters of privacy and data protection by sending an email to <a href='mailto:connect@redso.com.hk'>connect@redso.com.hk</a>.</p>
      <br />
      <p>In order to provide our services, we need to process your personal data. We do this with the utmost consideration.</p>
      <br />
      <p>What do we do with your information?</p>
      <p>Provide, operate and improve our services. All data is used to provide, operate and improve ReCards’s services and the information you give us, or we collect, is generally required to improve the user experience or to fulfill the service that we provide.</p>

      <br />
      <p>Information you give us or we collect about you</p>
      <p>You may directly or indirectly give us information about yourself in a variety of ways, such as when you contact us via our website, apply for a job, etc. ReCards processes personal data for the following purposes and based on the following legal bases:</p>
      <br />
      <p>Website visitor & prospective customer</p>
      <p>When you visit our website, we may collect the following information in order to provide the requested Service, generate website statistics and analytics, improve the customer experience on the website and target the right audience with our advertising:</p>
      <br />
      <li>Personal and contact information– name, company email and phone number.</li>
      <li>Traffic information– as provided by your web browser such as browser type, language and the address of the website from which you arrived and other traffic information such as IP address</li>
      <li>Clickstream behavior – such as which links you click and when</li>
      <li>Other personal data– data contained in your profile on third party social networks (Facebook, LinkedIn, etc.)</li>
      <br />
      <p>Communicate with you</p>
      <p>ReCards may also use your data to communicate relevant information regarding used or similar services. If you do not wish to receive such communication, please send an email to <a href='mailto:connect@redso.com.hk'>connect@redso.com.hk</a>.</p>
      <br />
      <p>Where do we store your personal data?</p>
      <p>We always strive to process your data within Hong Kong.</p>
      <br />
      <p>The data may however in certain situations be transferred to and processed in other regions by a supplier or subcontractor. As ReCards is committed to always protecting your data, ReCards will take all reasonable legal, technical, and organizational measures to ensure that your data is treated securely and with an adequate level of protection compared to and in line with at least the level of protection offered within the EU/EEA.</p>
      <br />
      <p>How long do we keep your personal data?</p>
      <p>We keep your data only as long as necessary to fulfil our obligations towards you and as required by statutory retention periods. Where we keep your data for purposes beyond those of our obligations, we keep the data only as long as necessary for the respective purpose.</p>
      <br />
      <p>Your rights to access, rectification and deletion</p>
      <p>Right to access your data.You may request a transcript of records if you would like to know and verify the information we have on you. The required information will be delivered within 60 days of receiving the verifiable request.</p>
      <br />
      <p>Right to rectification. You have the right to correct inaccurate or incomplete information about yourself.</p>
      <br />
      <p>Right to deletion (“right to be forgotten”). You have the right to request the deletion of your personal data insofar as this personal data is no longer necessary for the purpose it was collected. For Californian citizens the information will be deleted upon the request.</p>
      <br />
      <p>Contact. For issues relating to your rights to access, rectification and deletion please contact <a href='mailto:connect@redso.com.hk'>connect@redso.com.hk</a>.</p>
      <br />
      <p>What about cookies and similar tracking technologies?</p>
      <p>We use cookies and similar technologies to deliver a tailored and smooth online experience. For detailed information about how ReCards uses cookies and similar, please read our cookie statement.</p>
      <br />
      <p>Cookie statement</p>
      <p>Please reference ReCards’s website cookie statement: <a href={`https://re.cards/${lang}/CookieStatement.html`}>{`https://re.cards/${lang}/CookieStatement.html`}</a></p>
      <br />
      <p>Contact us</p>
      <p>Red Soldier Limited is a company incorporated and registered in Hong Kong whose registered office is at Unit 1201-1204, Texwood Plaza, No.6 How Ming Street, Kwun Tong, Kowloon, Hong Kong.</p>
      <br />
      <p>ReCards is the responsible entity (controller) for the processing of your personal data as a website visitor, a prospective customer or a job candidate. ReCards is subject to Hong Kong data protection legislation.</p>
      <br />
      <p>Revised: Nov 16, 2021.</p>
    </div>
  </Layout>
}